/* eslint-disable @typescript-eslint/no-explicit-any */
import { createRouter, createWebHistory } from "vue-router";
// RouterScrollBehavior

import routes from "./routeRoot";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // scrollBehavior,
  routes
});

router.beforeEach(async (to, from, next) => {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const title: any = to.meta.title;
  if (title) document.title = title;

  next();

});

export default router;
