import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
	name: "ViewerLayout",
	setup() {
        const state = reactive({
            appLogo: require('@/assets/images/logo-white.png')
        })
		return { 
            ...toRefs(state) 
        };
	},
});